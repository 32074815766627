var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "HR 월별 임금정보",
          columns: _vm.grid.columns,
          gridHeight: "300px",
          data: _vm.grid.data,
          columnSetting: false,
          expandAll: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }