<template>
  <div>
    <c-table
      ref="table"
      title="HR 월별 임금정보"
      :columns="grid.columns"
      gridHeight="300px"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-health-year-budget',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          iimAccidentId: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'costlossMonth',
            field: 'costlossMonth',
            label: '손실월',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'basicWage',
            field: 'basicWage',
            label: '기본일급',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'usuallyWage',
            field: 'usuallyWage',
            label: '통상시급',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'closeDay',
            field: 'closeDay',
            label: '해당월 휴업일수<br/>(주말제외)',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'contractUsuallyPay',
            field: 'contractUsuallyPay',
            label: '약정통상임금',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'hrCostlossMonth',
            field: 'hrCostlossMonth',
            label: 'HR급여 작업월(I/F)',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.sai.costloss.get.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.iimAccidentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
